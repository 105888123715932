import React from "react";
import { Navbar } from "../../tailwind";
import { Footer } from "../../tailwind/Footer";

export default () => (
  <>
      <Navbar />
      <div className="px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-lg md:px-24 lg:px-8">
          <div className="bg-white rounded border shadow mt-10 p-6">
              <h1 className="text-2xl text-gray-800 font-semibold py-1">What is PIP Insurance?</h1>
              <hr className="py-1"/>
              <p className="text-gray-700 text-sm">PIP Insurance, or personal insurance protection, is an addition to certain types
              of vehicle insurance that helps cover medical expenses in the event of a car accident, as well as wages lost due to
              substantial injuries. Another common name for PIP is 'no-fault' insurance because it pays out regardless of whose
              fault a motor vehicle accident is.</p><h2 className="text-lg text-gray-800 font-semibold py-2">What Benefits PIP Covers</h2><p className="text-gray-700 text-sm">If after an accident you file a claim
              against your PIP provider, you can claim payouts not only for damages incurred during the accident, but also for
              injuries, transportation to hospitals or clinics, and home maintenance that you're unable to perform your
              incapacitation such as lawn care.</p><h2 className="text-lg text-gray-800 font-semibold py-2">PIP Mandates</h2><p className="text-gray-700 text-sm">Currently, 13 states in the US require PIP insurance
              for all drivers: Washington D.C., Florida, Hawaii, Kansas, Kentucky, Massachusetts, Michigan, Minnesota, New Jersey,
              New York, North Dakota, Pennsylvania and Utah.</p><h2 className="text-lg text-gray-800 font-semibold py-2">Available - Not Required</h2><p className="text-gray-700 text-sm">While PIP coverage is required
              in 13 states, only some of the remaining 37 states in the US offer PIP coverage to drivers. They are: Alaska,
              Alabama, Arkansas, Arizona, California, Colorado, Connecticut, Georgia, Idaho, Illinois, Indiana, Iowa, Louisiana,
              Maine, Mississippi, Missouri, Montana, Nebraska, New Hampshire, New Mexico, Nevada, North Carolina, Ohio, Oklahoma,
              Rhode Island, South Carolina, Tennessee, Texas, Virginia, Vermont, Washington, Wisconsin, West Virginia and
              Wyoming.</p><h2 className="text-lg text-gray-800 font-semibold py-2">Reasonable Accommodations</h2><p className="text-gray-700 text-sm">Each state has different regulations regarding what PIP coverage
              must include. In Utah, acupuncture is considered a reasonable payout for PIP claims, while a similar claim in
              Illinois may be completely dismissed. Communicating with your insurance provider before incurring extraneous medical
              expenses to be covered by PIP will help avoid denied claims.</p><h2 className="text-lg text-gray-800 font-semibold py-2">Med-Pay Similarities</h2><p className="text-gray-700 text-sm">PIP often yields
              comparisons to Med-Pay insurance because of its ability to help cover costs directly relating to the accident such
              as vehicle or medical damages. The primary difference between the two types of care is that PIP covers both direct
              and indirect costs, including home maintenance and lost wages, while Med-Pay only covers costs directly relating to
              the accident.</p><h2 className="text-lg text-gray-800 font-semibold py-2">Limiting Court Battles</h2><p className="text-gray-700 text-sm">PIP insurance is designed to help keep personal injury cases out
              of the court system. No-fault states require insurance providers to pay out regardless of whose fault it is,
              preventing lawsuits between injured parties clogging up courts. Critics of PIP say that many legitimate lawsuits,
              such as those involving deliberate negligence, have been ushered away from court proceedings and deserve to be
              heard. In response to these claims, 12 of the 13 states with mandatory PIP coverage have revised their laws to
              include exceptions to the no-fault rules.</p><h2 className="text-lg text-gray-800 font-semibold py-2">Saving Time</h2><p className="text-gray-700 text-sm">By and large, PIP helps insurance providers
              avoid costly paperwork and investigations by allowing states to declare accidents as 'no-fault,' requiring insurance
              providers to pay out medical claims regardless of whose fault the accident was. Each driver's insurance provider
              pays for their own client's needs, regardless of who the state believes was at fault in the car accident. As a
              result, PIP insurance is considerably more expensive than basic coverage required in most states and insurance
              providers will often include exemption clauses for some PIP claims to avoid paying out.</p>
          </div>
      </div>
      <Footer/>

  </>
)